@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-primary: rgb(74, 221, 131); /* Green */
  --color-secondary: #000000;
  --color-secondary-light: #252525; /* Black */
  --color-gray: #f5f5f5; /* Light Gray */
  --font-primary: Poppins, sans-serif;
  --color-white:#ffffff;
}

body {
  font-family: var(--font-primary);
  background-color: #ffffff; /* Set background color to white */
  line-height: 1.6; /* Improve readability */
}

.bg-primary {
  background-color: var(--color-primary);
}

.bg-gray {
  background-color: var(--color-gray);
}

.text-primary {
  color: var(--color-primary);
}

.text-secondary {
  color: var(--color-secondary);
}

.text-white {
  color: #ffffff;
}

/* Inputs and Buttons */
input,
textarea {
  border: none;
  padding: 0.75rem;
  width: 100%;
  background-color: var(--color-gray); /* Light gray background for inputs */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

input:focus,
textarea:focus {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

button {
  /* background-color: var(--color-primary); */
  color: var(--color-secondary);
  padding: 0.75rem 1.25rem;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

button:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

/* Video Section */
.video-section {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.3); /* Dim the background without blur */
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  object-fit:contain;
}

/* Additional media query for mobile */
@media (max-width: 767px) {
  .background-video {
    width: 100vw; /* Ensure it spans full viewport width on mobile */
    height: 100vh; /* Ensure it spans full viewport height on mobile */
    object-fit:contain;
  }
}


.video-overlay {
  z-index: 20;
  color: white;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3); /* Slight dark overlay for better contrast */
  padding: 30px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* max-width: 800px; Add max-width for better alignment */
  margin: 0 auto; /* Center overlay */
}

.video-overlay h1 {
  font-size: 56px;
  font-weight: 700;
  text-shadow: 2px 4px rgba(0, 0, 0, 0.3); /* Text shadow for better visibility */
  margin-bottom: 20px; /* Space below heading */
}

.video-overlay p {
  font-size: 26px;
  font-weight: 400;
}

/* Responsive typography */
@media (max-width: 768px) {
  .video-overlay h1 {
    font-size: 36px;
  }
  .video-overlay p {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .video-overlay h1 {
    font-size: 28px;
  }
  .video-overlay p {
    font-size: 18px;
  }
}

/* Contact Section */
.contact-top {
  display: flex;
  justify-content: space-between;
  margin: 50px 0;
}

@media (max-width: 768px) {
  .contact-top {
    flex-direction: column;
  }
}

.contact-content,
.contact-form {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 30px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  margin: 50px 0;
}

/* Footer Section */
.footer-section {
  background-color: rgba(0, 0, 0, 0.9);
  padding: 40px;
  color: var(--color-gray);
  text-align: center; /* Center text */
}

.footer-group {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-right,
.foot-link {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 768px) {
  .footer-group {
    flex-direction: column;
    align-items: center;
  }
}

/* Carousel */
.slider-container {
  width: 100%;
  height: 550px;
  margin:0%;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  background-color: #f3f4f6;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
  .slider-container {
    height: 400px;
  }
}

.carousel__list {
  display: flex;
  list-style: none;
  width: 100%;
  height: 100%;
  justify-content: center;
  perspective: 1000px;
}

.carousel__item {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 0px;
  width: 300px;
  height: 500px;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: absolute;
  transition: all 0.6s ease;
}

.carousel__item.active {
  z-index: 5;
  opacity: 1;
}

/* Define carousel item background colors */
.carousel__item:nth-child(1) {
  background: linear-gradient(135deg, #1f4037 0%, #99f2c8 100%);
}

.carousel__item:nth-child(2) {
  background: linear-gradient(135deg, #3a1c71 0%, #d76d77 100%);
}

.carousel__item:nth-child(3) {
  background: linear-gradient(135deg, #fc5c7d 0%, #6a82fb 100%);
}

.carousel__item:nth-child(4) {
  background: linear-gradient(135deg, #11998e 0%, #38ef7d 100%);
}

.carousel__item:nth-child(5) {
  background: linear-gradient(135deg, #ff9966 0%, #ff5e62 100%);
}

/* Carousel Positions */
.carousel__item[data-pos="0"] {
  z-index: 5;
}

.carousel__item[data-pos="-1"],
.carousel__item[data-pos="1"] {
  opacity: 0.8;
}

.carousel__item[data-pos="-1"] {
  transform: translateX(-30%) scale(0.95);
  z-index: 4;
}

.carousel__item[data-pos="1"] {
  transform: translateX(30%) scale(0.95);
  z-index: 4;
}

.carousel__item[data-pos="-2"],
.carousel__item[data-pos="2"] {
  opacity: 0.4;
}

.carousel__item[data-pos="-2"] {
  transform: translateX(-60%) scale(0.85);
  z-index: 3;
}

.carousel__item[data-pos="2"] {
  transform: translateX(60%) scale(0.85);
  z-index: 3;
}

@media (max-width: 768px) {
  .carousel__item {
    width: 200px;
    height: 350px;
  }
}

@media (max-width: 480px) {
  .carousel__item {
    width: 150px;
    height: 300px;
  }
}


/* Explore Carousel */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

img,
picture {
  max-width: 100%;
  display: block;
}

.u-wrapper {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 60%;
}

.c-carousel {
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

.c-carousel-list {
  display: flex;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 200%;
  transition: transform 0.3s ease;
}

.c-carousel-item {
  width: 100px;  /* Set the width of the carousel item */
  height: 100px; /* Set the height of the carousel item */
  margin: 10px;
  border-radius: 16px;
  background-color: #000000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center; /* Center the logo vertically */
  justify-content: center; /* Center the logo horizontally */
}

.c-carousel-item img {
  max-width: 100%;  /* Ensure the image fits within the item */
  max-height: 100%; /* Ensure the image fits within the item */
  border-radius: 16px;
}

.LoginPage{
  display: flex;

  @media (max-width: 600px) {
    display: block;
  }
}
.LoginPage .brand,
.LoginPage .login
{
  width: 49%;
  @media (max-width: 600px) {
    width: 100%;
    margin-top: 75px;
  }
}