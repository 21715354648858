.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: black; /* Keeps consistent dark theme */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

/* Class to show navbar */
.show {
    transform: translateY(0);
}

/* Class to hide navbar */
.hide {
    transform: translateY(-100%);
}

.navbar:hover {
    background-color: rgba(34, 34, 34, 1);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.5);
}

.logo {
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;
    width: 50px;
    height: 50px;
}

.logo:hover {
    transform: scale(1.1);
}

.brandLogoText {
    display: flex;
    align-items: center;
}

.logoText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
}

.logoText > .brand {
    font-size: 24px;
    font-weight: 700;
    color: #ffffff;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
}

.logoText > .tagLine {
    font-size: 14px;
    font-weight: 400;
    color: #cccccc;
    margin-top: 2px;
}

.navLink {
    color: #ffffff;
    text-decoration: none;
    position: relative;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 600;
    transition: color 0.3s ease, transform 0.3s;
}

.navLink:hover {
    color: var(--color-primary);
    transform: translateY(-2px);
}

.navLink::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: var(--color-primary);
    transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
}

.navLink:hover::after {
    width: 100%;
    left: 0;
}

/* Hamburger menu for mobile */
.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    margin-left: 10px;
    transition: transform 0.3s ease;
}

.hamburger:hover {
    transform: scale(1.1);
}

.hamburger div {
    width: 25px;
    height: 3px;
    background-color: var(--color-white);
    margin: 5px 0;
    transition: background 0.3s ease;
}

/* Menu container for mobile */
.menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

/* Mobile-specific menu styles */
@media (max-width: 768px) {
    .hamburger {
        display: flex;
    }

    .menu {
        display: none;
        flex-direction: column;
        width: 100%;
        background-color: rgba(34, 34, 34, 0.95);
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        border-top: 2px solid var(--color-primary);
        z-index: 999;
    }

    .menu.active {
        display: flex;
    }

    .navLink {
        padding: 15px;
        width: 100%;
        text-align: center;
        transition: background 0.3s ease;
    }

    .navLink:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
}

/* Smooth slide-in animation for mobile menu */
@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
